import React, { useContext } from 'react';
import { Layout, HomePageCollectionGrip, PreHomePage } from 'components';
import ProductContext from 'context/ProductContext';
import { getLocalStorage } from '../components/utils/storage';

const IndexPage = () => {
  const { collections } = useContext(ProductContext);
  return (
    typeof window !== 'undefined' &&
    (getLocalStorage('country') ? (
      <Layout home={true}>
        <HomePageCollectionGrip
          collections={collections.filter(
            collection => collection.title !== 'Featured Hats'
          )}
        />
      </Layout>
    ) : (
      <PreHomePage />
    ))
  );
};

export default IndexPage;
